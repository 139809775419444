import useGetGeneralSettings from "../../providers/useGetGeneralSettings";
import { Empty } from "antd";
import React from "react";
import {ActiveMembership} from "@src/types";


export const getPOVResources
    : (pov: ("consumer" | "serviceowner")) => ({
    applicationsResource?: "owner_applications"|"applications",
    serviceItemsResource?: "owner_service_items"|"service_items",
    teamsResource?: "consumer_teams",
    servicesResource?: "consumer_services"|"services",
})
    = (pov: "consumer" | "serviceowner") => {
    if(pov==="consumer")
        return {
            servicesResource: "consumer_services",
            applicationsResource: "applications",
            serviceItemsResource: "service_items",
        }
    else if(pov==="serviceowner")
        return {
            teamsResource: "consumer_teams",
            servicesResource: "services",
            applicationsResource: "owner_applications",
            serviceItemsResource: "owner_service_items",
        }
    return {}
}


export const getCurrency = ():string => {
    const generalSettings = useGetGeneralSettings();

    let currency = generalSettings.find(setting => setting.name=='Currency');
    if(currency?.config?.ENABLED)
        return currency.config.currency;
    // default
    return "GBP";
}


export const getActiveMembership = () => {
    const userLocalStorage = JSON.parse(localStorage.getItem('user') as string)
    return userLocalStorage?.active_membership
}


export const setLocalStorageForUser = (activeMembershipData: ActiveMembership, token: string|null=null) => {
    // Used in
    //  - authProvider: for login
    //  - CreateTeam: when there is no team and the user wants to create the first team
    //  - TeamCreateModal: when user wants to create a new team besides the existing teams
    //  - ActivateMembership: when user wants to activate another team

    const userLocalStorage = JSON.parse(localStorage.getItem('user') as string)
    userLocalStorage["active_membership"] = activeMembershipData
    localStorage.setItem('user', JSON.stringify(userLocalStorage));

    // localStorage.setItem('active_membership', JSON.stringify(activeMembershipData))
    localStorage.setItem('role', activeMembershipData.role)

    if(token) {
        // login
        localStorage.setItem('token', token);
        if (!("pov_preference" in localStorage))
            localStorage.setItem ('pov_preference', activeMembershipData.pov_preference === "serviceowner" ? "SERVICE_OWNER" : "CONSUMER");
    } else {
        // change active team
        localStorage.setItem('pov_preference', activeMembershipData.pov_preference==="serviceowner"?"SERVICE_OWNER":"CONSUMER");
    }

}

export const setActiveTeamLocalStorage = (activeTeamData: any) => {
    // Called only when the team data is updated
    let activeMembership = getActiveMembership()
    if(activeMembership)
        activeMembership["team"] = activeTeamData
    else
        activeMembership = {"team": activeTeamData}

    setLocalStorageForUser(activeMembership)
}

export const NotFoundComponent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
