import {Button, Card, Col, Row, Tabs, Typography} from 'antd';
import React, { useEffect, useState } from 'react';
import { ListProps, useDataProvider, useNotify} from 'react-admin';
import { Link } from 'react-router-dom';
import useGetGeneralSettings from '../../providers/useGetGeneralSettings';
import ApiKeyList from './ApiKeyList';
import TeamMappings from './SubComponents/TeamMappings';
import TeamEdit from "./TeamEdit";
import TeamMembershipList from "./TeamMembershipList";
import ActivateMembership from "./SubComponents/ActivateMembership";
import TeamMetadataCard from "@src/components/Teams/SubComponents/TeamMetadataCard";
import {getActiveMembership} from "@src/components/Common/utils";

const { TabPane } = Tabs;
const { Text } = Typography;


const TeamDetailView = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify();
  const generalSettings = useGetGeneralSettings();
  const [isPendingRequest, setIsPendingRequest] = useState(false)
  const ldap = generalSettings.find(setting => setting.name=='LDAP')

  const activeMembership = getActiveMembership()

  useEffect(() => {
    if (!activeMembership?.active) {
      dataProvider.getList('team_join_requests', {
        pagination: { page: 1, perPage: 20 },
        sort: { field: "name", order: "asc" },
        filter: {}
      })
        .then((response: any) => {
          if(response.data[0]) {
            setIsPendingRequest(!response.data[0].is_accepted)
          }
        }).catch((error: any) => {
          console.error(error)
          notify('Error checking for pending join requests', 'error')
        })
    }
  }, [activeMembership])

  return (
      activeMembership?.active ?
      <>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Members" key="1">
            <TeamMembershipList/>
          </TabPane>
          <TabPane tab="Metadata" key="2"><TeamMetadataCard teamId={activeMembership.team.id} /></TabPane>
          {activeMembership?.role == 'ADMIN' && <TabPane tab="API Keys" key="3"><ApiKeyList /></TabPane>}
          {activeMembership?.role == 'ADMIN' && ldap?.ENABLED && <TabPane tab="Team Mappings" key="4"><TeamMappings /></TabPane>}
          {activeMembership?.role == 'ADMIN' && <TabPane tab="Team Settings" key="5"><TeamEdit resource={'teams'} basePath={'teams'} id={activeMembership.team.id}/></TabPane>}
        </Tabs>
      </>
          :
      <>
        <Card
          bordered={false}
          className="criclebox  mb-24"
          title="Team Management"
        >
          {isPendingRequest && <Text>Join Request is pending approval.</Text>}
          {!isPendingRequest && <><Text>You're currently not part of any teams. Click below to create a team</Text>
            <Row style={{ 'width': '20%', marginTop: '20px' }} justify="space-between">
              <Col span={12}><Button type='primary'><Link to="/teams/create">Create Team</Link></Button></Col>
            </Row></>}
        </Card>
      </>
  )
}

const TeamDetail = (props: ListProps) => {
  return (
    <>
      <ActivateMembership/>
      {/*
      There is a wrong design here . This component fetches the list of teams for the user.
      However, in the UI, The page is used to show memberships list, the active team's metadata and settings, etc.
      So, fetching the list of teams is unnecessary.
      It could have been better to have MembershipListView rather than TeamList,
      and wrote the components based on the data from memberships list than team list.
      */}
      {/*<ListBase {...props}>*/}
        <TeamDetailView />
      {/*</ListBase>*/}
    </>
  )
}

export default TeamDetail;
