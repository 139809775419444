import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Collapse, Modal, Row, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { HttpError, useDataProvider, useGetIdentity, useNotify, useQuery, useRedirect } from 'react-admin';
import { ExtendedDataProvider, ServiceItem } from '@src/types';
import SchemaBasedForm from './FormGenerator/SchemaBasedForm';
import { useBuilderWizardContext } from '../BuilderWizardProvider';
import AddApplicationModal from './AddApplicationModal';
import ApplicationPanel from './ApplicationPanel';
import useDeclarationDataEditor from './useDeclarationDataEditor';
import ValidationErrors from '../ValidationErrors';
import { isSubmitButtonVisible } from './utils'
import TeamMetadataModal from './TeamMetadataModal';
import {getActiveMembership} from "@src/components/Common/utils";

const { confirm } = Modal;

export declare type Key = string | number;

interface ServiceItemContainer {
    schema: any;
    serviceItem: ServiceItem | null;
    service: string | null;
}

const SubmissionEditor = () => {
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const [newApplicationsCreated, setNewApplicationsCreated] = useState<string[]>([])
    const [isAppModalOpen, setIsAppModalOpen] = useState<boolean>(false)
    const [isTeamMetadataModalOpen, setIsTeamMetadataModalOpen] = useState<boolean>(false)
    const { identity } = useGetIdentity()
    const [isSchemaLoading, setIsSchemaLoading] = useState<boolean>(false)
    const [serviceItemsWithErrors, setServiceItemsWithErrors] = useState<ServiceItem[]>([]);
    const notify = useNotify();
    const redirect = useRedirect();
    const [selectedServiceItemContainer, setSelectedServiceItemContainer] = useState<ServiceItemContainer>({
        schema: null,
        serviceItem: null,
        service: null
    });

    const activeMembership = getActiveMembership()
    const teamName =  activeMembership?.team?.name

    const { setSelectedApplication, selectedApplication, setServiceItemsData, setSelectedService, selectedService, setIsCurrentStepValid, setDeclarationData, declarationData, setValidationErrors, validationErrors } = useBuilderWizardContext();
    const {
        getServiceItemData,
        partialUpdateDeclaration,
        deleteAppFromDeclaration,
        partialDeleteDeclaration,
        partialUpdateAppMetadata,
        partialUpdateTeamMetadata,
        renameAppInDeclaration
    } = useDeclarationDataEditor({ declarationData, setDeclarationData });

    const { Title } = Typography;
    const { data: submissionListData, loaded, error: submissionListError } = useQuery({
        type: 'getList',
        resource: 'submissions',
        payload: {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "DESC" },
            filter: {}
        }
    });

    const validateSubmission = () => {
        dataProvider.createAction('submissions', { data: declarationData, verb: 'validate/' })
            .then((response: any) => {
                if (response?.data?.is_valid) {
                    notify('Validation successful', "success")
                    setValidationErrors(false)
                }
            }).catch((error: HttpError) => {
                console.error(error.body)
                notify('Validation failed', "error")
                setValidationErrors(error.body.errors)
            })

    }
    const submitSubmission = () => {
        dataProvider.createAction('submissions', { data: declarationData, verb: 'submit/' })
            .then((response: any) => {
                notify('Submission successful', "success")
                if (response?.data?.id) {
                    redirect(`/submissions/${response?.data?.id}`)
                }
            }).catch((error: HttpError) => {
                console.error(error.body)
                notify('Submission failed', "error")
            })

    }

    useEffect(() => {
        if (submissionListError)
            notify('Fetching latest submission failed', "error")

        if (!teamName) {
            return
        }
        if (loaded && !declarationData && submissionListData[0]) {
            // setServiceItemsData({ [`${selectedApplication?.name}`]: appServiceItemData })
            const latestSubmission = submissionListData[0]
            setDeclarationData(latestSubmission.request_json)
            // setIsCurrentStepValid(true)
        } else if (loaded && !declarationData && (!submissionListData || submissionListData.length == 0)) {
            setDeclarationData({ [teamName]: {} })
        }

    }, [submissionListData, loaded, submissionListError, teamName]);

    useEffect(() => {
        setIsCurrentStepValid(serviceItemsWithErrors.length == 0)
    }, [serviceItemsWithErrors])

    const getServiceSchema = async (serviceName: string | Number | null) => {
        return dataProvider.getList('browse_services', {
            pagination: { page: 1, perPage: 20 },
            sort: { field: "name", order: "asc" },
            filter: {
                'name_exact': serviceName
            }
        }
        ).then((data: any) => {
            return data
        })
    }
    const handleItemUpdate = (formData: any) => {
        if(selectedServiceItemContainer.serviceItem && formData?.name) 
        {
        const updatedServiceItem = partialUpdateDeclaration({
            applicationName: selectedApplication,
            serviceName: selectedServiceItemContainer.serviceItem?.serviceName,
            serviceItemName: selectedServiceItemContainer.serviceItem?.name,
            updatedData: formData
        })
        setSelectedServiceItemContainer(prev => ({
            ...prev,
            serviceItem: updatedServiceItem
        }));
    }
    }

    const handleSuccessSubmit = (formData: any) => {
        setServiceItemsWithErrors(prevItemsWithErrors =>
            prevItemsWithErrors.filter(erroredItem => !(erroredItem.applicationName === selectedServiceItemContainer.serviceItem!.applicationName && erroredItem.name === selectedServiceItemContainer.serviceItem!.name))
        )
    }

    const handleDeleteApp = (applicationName: string) => {
        deleteAppFromDeclaration({ applicationName })
    }

    const handleRenameApp = (previousName: string, newName: string) => {
        renameAppInDeclaration({ applicationName: previousName, updatedApplicationName: newName })
    }

    const handleDeleteItem = () => {
        confirm({
            okText: "Remove",
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to remove this service item from the declaration?',
            onOk() {
                partialDeleteDeclaration({ 
                    applicationName: selectedApplication, 
                    serviceName: selectedServiceItemContainer.serviceItem?.serviceName!, 
                    serviceItemName: selectedServiceItemContainer.serviceItem?.name! 
                })
                setSelectedServiceItemContainer({
                    schema: null,
                    serviceItem: null,
                    service: null
                })
            },
        });
    }
    const handleItemError = (errors: any) => {
        setServiceItemsWithErrors(prevItemsWithErrors => [...prevItemsWithErrors, selectedServiceItemContainer.serviceItem!]);
        errors && setIsCurrentStepValid(false)
    }

    const onAddNewApp = (appName: string) => {
        if (!teamName) {
            return
        }
        const amendedData = JSON.parse(JSON.stringify(declarationData));
        if (amendedData[teamName][appName]) {
            console.warn("app already exists")
            notify('Application with that name already exists', 'error')
            return;
        }
        setNewApplicationsCreated((prevData) => [...prevData, appName]);
        amendedData[teamName][appName] = { services: {} }
        setDeclarationData(amendedData)
        setSelectedApplication(appName)
    }

    const onEditTeamMetadata = (teamMetadata: object | null) => {
        partialUpdateTeamMetadata({ updatedData: teamMetadata })
    }

    const onAddServiceItem = async (serviceName: string, serviceItemName: string) => {
        const schema = await getServiceSchema(serviceName);
        
        const updatedServiceItem = partialUpdateDeclaration({
            applicationName: selectedApplication,
            serviceName: serviceName,
            serviceItemName: serviceItemName,
            updatedData: { name: serviceItemName },
        })

        setSelectedServiceItemContainer({
            schema: schema.data[0]?.schema,
            serviceItem: updatedServiceItem,
            service: serviceName
        });
        setSelectedService(serviceName);
    }

    const onEditAppMetadata = (appName: string, appMetadata: object) => {
        partialUpdateAppMetadata({ applicationName: appName, updatedData: appMetadata },)
    }


    const handleServiceItemNodeSelect = async (nodes: Key[], info: any) => {
        setIsSchemaLoading(true);
        try {
            const itemName = info?.node?.key.split('\x1F');
            if (itemName.length < 2) {
                console.error('Invalid item name');
                return;
            }
            const serviceItemName = itemName[1];
            const serviceName = itemName[0];
            const serviceItem: ServiceItem = getServiceItemData(selectedApplication, serviceName, serviceItemName, declarationData);
            
            const schema = await getServiceSchema(serviceItem.serviceName);
            setSelectedServiceItemContainer({
                schema: schema.data[0]?.schema,
                serviceItem: serviceItem,
                service: serviceItem.serviceName
            });
        } finally {
            setIsSchemaLoading(false);
        }
    }


    const handleAppSelect = (key: string | string[]) => {
        if (Array.isArray(key)) {
            console.warn("Received an array of keys, only using the first element.");
            key = key[0];
        }
        // if no app is expanded clear item view
        setSelectedServiceItemContainer({
            schema: null,
            serviceItem: null,
            service: null
        })
        setSelectedApplication(key)
    }


    if (identity && !teamName) {
        return <Alert message="Please create or join a team to use the Submission Builder" type="error" />
    }

    if (!(declarationData && teamName)) {
        return <Spin style={{ 'width': '100%' }} />
    }

    const isRemoteValid = (typeof validationErrors === 'boolean' && validationErrors === false);
    return (
        <Card
            className="header-solid"
            bordered={false}
        >
            {validationErrors && <ValidationErrors errors={validationErrors} />}
            <Row>
                <Col style={{ borderRight: '1px solid #dddddd' }} span={6}>
                    <Row gutter={[0, 32]}>
                        <Col span={24}>
                            <Collapse
                                onChange={handleAppSelect}
                                accordion
                                // @ts-ignore
                                activeKey={selectedApplication}
                            >
                                {
                                    Object.keys(declarationData[teamName])
                                        .filter(application => application !== 'metadata')
                                        .map((application: string) => (
                                            <ApplicationPanel
                                                key={application}
                                                serviceItemsWithErrors={serviceItemsWithErrors}
                                                application={application}
                                                selectedServiceItem={selectedServiceItemContainer.serviceItem}
                                                setSelectedService={setSelectedService}
                                                selectedService={selectedService}
                                                appDeclarationData={declarationData[teamName]?.[application]}
                                                onNodeSelect={handleServiceItemNodeSelect}
                                                onAddServiceItem={onAddServiceItem}
                                                onEditAppMetadata={onEditAppMetadata}
                                                onRemoveApp={handleDeleteApp}
                                                onRenameApp={handleRenameApp}
                                                newApplicationsCreated={newApplicationsCreated}
                                            />
                                        )
                                        )
                                }
                            </Collapse>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '32px' }}>
                        <Col >
                            <Button icon={<PlusOutlined />} onClick={() => setIsAppModalOpen(true)}>New Application</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '5px' }}>
                        <Col >
                            <Button icon={<EditOutlined />} onClick={() => setIsTeamMetadataModalOpen(true)}>Team Metadata</Button>
                        </Col>
                    </Row>
                    {isTeamMetadataModalOpen && <TeamMetadataModal metadata={declarationData[teamName]?.metadata} handleModalSubmit={onEditTeamMetadata} modalVisible={isTeamMetadataModalOpen} setModalVisible={setIsTeamMetadataModalOpen} />}
                    {isAppModalOpen && <AddApplicationModal handleModalSubmit={onAddNewApp} modalVisible={isAppModalOpen} setModalVisible={setIsAppModalOpen} />}
                </Col>

                {!selectedServiceItemContainer.schema &&
                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} span={18}>
                        {isSchemaLoading && <Spin style={{ 'width': '100%' }} />}
                        {!selectedServiceItemContainer.schema &&
                            <Title style={{ color: 'gray' }} level={5}>
                                {Object.keys(declarationData[teamName]).length === 0
                                    ? "Click the 'New Application' button to get started"
                                    : "Select or Add a Service Item"}
                            </Title>
                        }
                    </Col>
                }

                <Col
                    style={{
                        paddingLeft: '30px',
                        display: 'flex',
                        justifyContent: 'left',
                    }}
                    span={18}
                >
                    {selectedServiceItemContainer.schema && !isSchemaLoading && <SchemaBasedForm 
                        onError={handleItemError} 
                        onSave={handleItemUpdate} 
                        onSubmit={handleSuccessSubmit} 
                        formData={selectedServiceItemContainer.serviceItem?.data} 
                        key={selectedServiceItemContainer.schema?.id} 
                        style={{
                            maxWidth: '600px',
                            width: '100%',
                            padding: '5px 15px'
                        }} 
                        recordId={selectedServiceItemContainer.schema?.id} 
                        schema={selectedServiceItemContainer.schema?.schema} 
                    />}
                    {selectedServiceItemContainer.schema && !isSchemaLoading && <Button style={{ position: 'absolute', bottom: 0, right: 0 }} onClick={handleDeleteItem} danger><DeleteOutlined /> Remove Service item</Button>}
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col span={24}>
                    <Space>
                        <Button onClick={() => validateSubmission()}>Validate</Button>
                        {
                            isSubmitButtonVisible() &&
                            <Button type='primary' disabled={!isRemoteValid} onClick={() => submitSubmission()}>Submit</Button>
                        }
                    </Space>
                </Col>
            </Row>
        </Card>
    )
}

export default SubmissionEditor;